import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class UtilService {
  constructor(private snackbar: MatSnackBar) {}

  async converterFileToBase64(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
    });
  }

  private getSnackbarStyle(style: "success" | "error" | "alert") {
    switch (style) {
      case "success":
        return ["sucess-alert"];
      case "error":
        return ["error-alert"];
      case "alert":
        return ["warning-alert"];
    }
  }

  async showSnackbar(
    message: string,
    style: "success" | "error" | "alert",
    duration = 2500,
    config?: MatSnackBarConfig<any>
  ) {
    const snack = this.snackbar.open(message, undefined, {
      panelClass: this.getSnackbarStyle(style),
      horizontalPosition: "right",
      verticalPosition: "top",
      duration,
      ...config,
    });

    snack._dismissAfter(3000);
  }

  formatThousandNumber(n: number) {
    if (n >= 1000) {
      const formattedNumber = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 1,
      }).format(n / 1000);
      return formattedNumber + "K";
    } else {
      return new Intl.NumberFormat("en-US").format(n);
    }
  }

  formatTimerInMinutes(n: number): string {
    const minutes: number = Math.trunc(n / 60);
    const seconds: number = n % 60;

    const formatedMinutes: string =
      minutes >= 10 ? String(minutes) : `0${minutes}`;
    const formatedSeconds: string =
      seconds >= 10 ? String(seconds) : `0${seconds}`;

    return `${formatedMinutes}:${formatedSeconds}`;
  }
}
