export const environment = {
  production: false,
  ENV: "prd",
  BASE_URL: "https://api.candleconsultas.com.br/api",
  BASE_URL_SOCKET: "https://api.candleconsultas.com.br/",
  // BASE_URL: "http://localhost:3000/api",
  // BASE_URL_SOCKET: "http://localhost:3002",
  TOKEN_CANDLE: "00e20d6bbc6f63ccf9c843cb1ebeb655",
  AFRENT_PAY_INTERNAL_TOKEN:
    "fqnsd3a9xqx96br79ob4m8maqff8yilkszb4424fqxmo6mkvt5e896dn1yuv992s",
};
